import request from '@/utils/request'

//用户信息
export function userInfo(params) {
    return request({
        url: `/api/selectByClienteleId`,
        method: 'get',
        params
    })
}
// 下单人信息
export function orderUserInfo(params) {
    return request({
        url: `api/getOrderPersonTel`,
        method: 'get',
        params
    })
}

//获取空闲车辆
export function emptyCar(params) {
    return request({
        url: `/api/getVehicleListByBrandNoPlan`,
        method: 'get',
        params
    })
}
//获取空闲司机
export function emptyDriver(params) {
    return request({
        url: `/api/getStaffListNoPlan`,
        method: 'get',
        params
    })
}

//下单
export function subOrder(data) {
    return request({
        url: `/api/setDataByOrder`,
        method: 'post',
        data
    })
}