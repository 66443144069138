<template>
  <div class="online-to">
    <Header></Header>
    <div class="main">
      <div class="head">
        <!-- <div class="flex flex-row align-center consulting-phone">
          <img src="../assets/onlineTo/phone.png" alt="" />
          <div>如需帮助请咨询：<span>0832-2032666 18064942803</span></div>
        </div>
        <div class="prompt">
          <span>温馨提示：用车车型和车牌号以实际车型车牌号为准。</span>
          <span
            >因我司车辆和驾驶员都会根据订单情况提前作出安排，为了不影响您的行程，请下单选择用车时间、地点、乘车人数时务必准确。如有临时调整，请务必提前打电话沟通以便调整。</span
          >
        </div> -->
         <!-- <div class="prompt" style="margin-bottom: 10px;">
          <span style="font-size: 18px;padding: 12px 18px 12px 18px;">公告 -- 近期接部分客户单位反映，用车费用上浮较大。现就反映情况作如下说明：用车费用收费方式没有任何变化，主要增幅原因是汽油油价上涨较大，2022年5月31日汽油油价相比2021年1月30日的汽油油价上涨45%。</span>
        </div> -->
      </div>
      <div class="message">
        <h2>填写信息</h2>
        <!-- <p>带*必填，不带*号非必填</p> -->
        <div class="user-from">
          <div class="flex flex-row basic">
            <div class="flex flex-row customer-unit">
              <div class="title-name">
                客户单位<span class="title-selected">*</span>
              </div>
              <el-input
                class="inputCss"
                v-model="clienteleName"
                placeholder="请输入客户单位"
                disabled="disabled"
              ></el-input>
            </div>
            <div class="flex flex-row submite-type">
              <div class="title-name">
                订单类型<span class="title-selected">*</span>
              </div>
              <el-select
                class="inputCss"
                v-model="orderType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="flex flex-row out-site">
              <div class="title-name">
                出发地点<span class="title-selected">*</span>
              </div>
              <el-input
                class="inputCss"
                v-model="reservationStartAddr"
                placeholder=""
              ></el-input>
            </div>
            <div class="flex flex-row">
              <div class="title-name">
                目标地点<span class="title-selected">*</span>
              </div>
              <el-input
                class="inputCss"
                v-model="reservationEndAddr"
                placeholder=""
              ></el-input>
            </div>
          </div>

          <!-- <div class="flex flex-row align-center select-time">
            <div>半日租</div>
            <div>日租</div>
            <div>随时用车</div>
          </div> -->
          <div class="flex flex-row carlmd">
            <div class="flex flex-row car-time">
              <div class="title-name">
                用车时间<span class="title-selected">*</span>
              </div>
              <!-- <el-date-picker
                v-model="timeValue"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="-"
                start-placeholder="请选择预计用车时间"
                end-placeholder="请选择预计结束时间"
                align="right"
                value-format="yyyy-MM-dd HH:mm:ss"
                @change="carTime"
              >
              </el-date-picker> -->
                  <el-date-picker
                    v-model="timeValue1"
                    type="datetime"
                    placeholder="请选择预计用车时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="carTime"
                  >
                  </el-date-picker>
                  -
                  <el-date-picker
                    v-model="timeValue2"
                    type="datetime"
                    placeholder="请选择预计结束时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    @change="carTime"
                  >
                  </el-date-picker>
            </div>
            <div class="flex flex-row with-car">
              <div class="title-name">
                用车人<span class="title-selected">*</span>
              </div>
              <!-- <button type="button" @click="addPeople">添加用车人</button> -->
              <el-select
                v-model="value"
                multiple
                filterable
                allow-create
                default-first-option
                placeholder="请添加用车人"
                size="small"
                @change="withOption(value)"
              >
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.username"
                  :value="item.username"
                >
                  <span style="float: left">{{ item.username }}</span>
                  <span
                    style="
                      float: right;
                      color: #8492a6;
                      font-size: 13px;
                      margin-right: 20px;
                    "
                    >{{ item.mobile }}</span
                  >
                </el-option>
              </el-select>
            </div>
            <div class="flex flex-row with-phone">
              <div class="title-name">
                用车人电话<span class="title-selected">*</span>
              </div>
              <el-input
                class="inputCss"
                placeholder="请输入（用于接收短信通知）"
                v-model="withPhone"
              ></el-input>
            </div>
          </div>

          <!-- <div class="flex flex-row submite-type" v-for="(i, index1) in num" :key='index1'>
            <div class="title-name">用车人姓名<span class="title-selected">*</span></div>
            <el-select class="inputCss" v-model="valueOne[index1]" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span @click="close(index1)">删除</span>
          </div> -->
          <div class="flex flex-row basic">
            <div class="flex flex-row with-phone">
              <div class="title-name">
                下单人电话<span class="title-selected">*</span>
              </div>
              <el-input
                class="inputCss"
                placeholder="请输入下单人电话"
                v-model="orderPersonTel"
              ></el-input>
            </div>
            <div class="flex flex-row with-phone">
              <div class="title-name">
                乘车人数<span class="title-selected">*</span>
              </div>
              <el-input
                v-model="peopleNum"
                class="inputCss"
                placeholder="请输入乘车人数"
                type="number"
              ></el-input>
            </div>
            <div class="flex flex-row with-phone">
              <div class="title-name">用车事由</div>
              <el-input
                v-model="vehicleUseReason"
                class="inputCss"
                placeholder="请输入用车事由（非必填）"
              ></el-input>
            </div>
            <div class="flex flex-row with-phone">
              <div class="title-name">备注</div>
              <el-input
                v-model="note"
                class="inputCss"
                placeholder="请输入（需求车型及其他）"
              ></el-input>
            </div>
          </div>

          <!-- <div class="flex flex-row basic">

          </div> -->
        </div>
      </div>
      <div v-if="carsWitch == 1 && carShow" class="select-car">
        <h2>选择品牌</h2>
        <div class="flex flex-row car-list">
          <div class="flex flex-row submite-type">
            <div class="title-name">
              选择类型<span class="title-selected">*</span>
            </div>
            <el-select
              class="inputCss"
              v-model="vehicleTypeId"
              placeholder="请选择"
              @change="carBtn()"
            >
              <el-option
                v-for="item in carType"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>

          <!-- <div
            v-for="(list, index) in carType"
            :key="index"
            class="flex flex-column justify-between align-center car-item"
          >
            <span>{{ list.name }}</span>
            <img
              :src="require('../assets/onlineTo/' + list.img + '.jpg')"
              alt=""
            />
            <button
              type="buttom"
              @click="carBtn(list.id)"
              :class="{ 'selected-btn': vehicleTypeId == list.id }"
            >
              选择
            </button>
          </div> -->
        </div>
      </div>
      <div v-if="carsWitch == 1 && carShow" class="select-car select-car2">
        <!-- <h2>选择品牌</h2> -->
        <div :style="{ left: left }" class="flex brand-car">
          <div
            v-for="(list, index) in carDataList"
            :key="index"
            class="car-item car-type"
          >
            <span>{{ list.vehicleBrand }}</span>
            <img :src="host + list.vehicleImg" alt="" />
            <div class="price">
              <span v-if="list.described != null &&  list.described != ''">{{list.described}}</span>
              <span v-else>¥ {{list.vehicleFee }}/天</span>
            </div>
            <button
              type="buttom"
              @click="carBtn2(list.cid)"
              :class="{ 'selected-btn2': brandId == list.cid }"
            >
              选择
            </button>
          </div>
        </div>
        <div
          v-show="carTotal > 1 && page != 0"
          class="car-btn car-left-btn"
          @click="leftBtn"
        >
          <i class="el-icon-arrow-left"></i>
        </div>
        <div
          v-show="carTotal > 1 && carTotal - page != 1"
          class="car-btn car-right-btn"
          @click="rightBtn"
        >
          <i class="el-icon-arrow-right"></i>
        </div>
      </div>
      <div class="select-equipped">
        <h2>选择配备</h2>
        <div class="flex flex-row align-center car-list">
          <!-- <div class=" alternate">
            <div v-if="carShow" class="flex flex-row target-site">
              <div class="title-name">另选车辆</div>
              <el-select
                v-model="alternateValue1"
                @change="searchSelect1(alternateValue1)"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in cities1"
                  :key="item.cid"
                  :label="item.plateNum"
                  :value="index"
                >
                </el-option>
              </el-select>
            </div>
            <div class="flex flex-row target-site">
              <div class="title-name">另选司机</div>
              <el-select
                v-model="alternateValue2"
                @change="searchSelect2(alternateValue2)"
                placeholder="请选择"
              >
                <el-option
                  v-for="(item, index) in cities2"
                  :key="item.cid"
                  :label="item.staffName"
                  :value="index"
                >
                  <span class="productKind" style="float: left">{{
                    item.staffName
                  }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.tel
                  }}</span>
                </el-option>
              </el-select>
            </div>
          </div> -->
          <div class="selectCheck">
            <el-checkbox-group
              v-if="carShow"
              v-model="checkList"
              class="
                flex flex-row
                justify-between
                align-center
                selectCheck-item1
              "
            >
              <el-checkbox
                @change="dialogVisibleBtn"
                label="自带驾驶员（自带驾驶员请勾选）"
              ></el-checkbox>
              <!-- <el-checkbox label="是否随从就餐（随从就餐请勾选）"></el-checkbox>
              <el-checkbox label="是否随从住宿（随从住宿请勾选）"></el-checkbox> -->
            </el-checkbox-group>
            <el-checkbox-group
              v-model="checkList1"
              class="flex flex-column selectCheck-item"
            >
              <el-checkbox
                @change="dialogVisibleBtn2"
                label="是否随从就餐（随从就餐请勾选）"
              ></el-checkbox>
              <el-checkbox
                @change="dialogVisibleBtn3"
                label="是否随从住宿（随从住宿请勾选）"
              ></el-checkbox>
              <!-- <el-checkbox @change="checkedState2 = !checkedState2" label="是否随从住宿（随从住宿请勾选）"></el-checkbox>
              <el-checkbox @change="checkedState3 = !checkedState3" label="是否随从住宿（随从住宿请勾选）"></el-checkbox> -->
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <button
        class="flex flex-row justify-center align-center submit-order"
        type="button"
        @click="submitOrder"
      >
        提交订单
      </button>
    </div>
    <el-dialog title="安全约定" :visible.sync="dialogVisible" width="50%">
      <!-- <span>这是一段信息</span> -->
      <div>
        车辆租赁是指由用车方自聘驾驶员完成运输任务，在车辆租赁期间，如发生交通事故或其他事件经相关部门鉴定非机械事故所造成车辆损坏、人员伤亡、停运等经济损失，经双方协商确定，由车辆租赁方承担以下责任：
      </div>
      <p>
        一、车辆在租赁期间发生交通事故或其他事件，造成车辆停驶，在停驶期间，车辆租赁方按每天租车价的50%承担停驶损失。
      </p>
      <p>
        二、车辆在租赁期间发生交通事故，造成车辆损坏及人员伤亡，所产生的经济损失，经向保险公司索赔后，保险公司免赔拒赔部分由车辆租赁方承担。
      </p>
      <p>
        三、车辆在租赁期间，发生交通事故或其他事件应及时通知车辆公司，事故处理由租赁方全权负责，公司提供手续及负责保险理赔。
      </p>
      <p>
        四、如租赁方授权我司派员协助处理事故，处理事故所产生的差旅费、交通费、住宿费等相关费用由车辆租赁方承担。
      </p>
      <p>
        五、本约定系车辆租赁协议不可分割的部分，若与协议冲突则优先适用本约定。
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogState(2)">取 消</el-button>
        <el-button type="primary" @click="dialogState(1)">确 定</el-button>
      </span>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import host from "@/config/host";
import { carLista } from "@/api/home";
import {
  userInfo,
  orderUserInfo,
  emptyCar,
  emptyDriver,
  subOrder,
} from "@/api/onlineTo";

export default {
  name: "OnlineTo",
  components: {
    Header,
    Footer,
  },
  inject: ["reload"],
  data() {
    return {
      host: host, //请求地址头
      // 请求参数
      clienteleName: "", //客户单位
      reservationStartAddr: "", //出发地点
      reservationEndAddr: "", //目标地点
      peopleNum: "", //乘车人数
      vehicleUseReason: "", //用车事由
      note: "", //备注
      carsWitch: 1, //类型品牌开关
      // aa: "`${label}${value}`",
      // num:[],
      // valueOne:[{value0:'',value1:'',value2:'',value3:'',value4:'',value5:''}],
      closeIndex: null,
      // 订车类型
      options: [
        {
          value: "1",
          label: "公务用车（派车派人）",
        },
        {
          value: "2",
          label: "公务用车（只派车）",
        },
        {
          value: "3",
          label: "公务用车（只派人）",
        },
        {
          value: "4",
          label: "非公务用车",
        },
      ],
      // value: "",
      orderType: "1", //订单类型
      pickerOptions: {
        shortcuts: [
          {
            text: "半日租",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              end.setTime(end.getTime() + 3600 * 1000 * 4);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "日租",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              end.setTime(end.getTime() + 3600 * 1000 * 8);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "随时用车",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
        // disabledDate(date) {
        //   return date.getTime() <   Date.now() - 8.64e7;
        // },
        // allDate:[],
        // onPick({ maxDate, minDate }){
        //   console.log(this.timeValue)
        //   let min,max;
        //   new Date().getHours(); //获取当前小时数(0-23)
        //   new Date().getMinutes(); //获取当前分钟数(0-59)
        //   new Date().getSeconds(); //获取当前秒数(0-59)
        //   min= new Date().getHours()+':'+new Date().getMinutes()+':'+new Date().getSeconds();
        //   max = (new Date().getHours()+1)+':'+new Date().getMinutes()+':'+new Date().getSeconds();
        //   console.log(min,max)
        //   this.allDate = [min,max];
        //   console.log(this.allDate)
        // }
      },
      // value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      timeValue: ["", ""], //用车时间
      timeValue1: "", //用车时间
      timeValue2: "", //用车时间
      // 添加乘车人
      options2: [],
      value: [], //用车人
      value2: "", //拼接用车人
      withPhone: "", //用车人电话
      orderPersonTel: "", //下单人电话
      // 选择车型
      value3: "1", //选择车型
      carType: [
        {
          id: "1",
          name: "轿车车型",
          img: "jiaoche",
        },
        {
          id: "2",
          name: "商务车型",
          img: "shangwuche",
        },
        {
          id: "3",
          name: "越野车型",
          img: "yueyeche",
        },
        {
          id: "4",
          name: "客车车型",
          img: "keche",
        },
      ],
      vehicleTypeId: "1", //车类型id
      carDataList: [], //品牌信息
      brandId: null, //品牌id
      vehicleId: null,
      plateNum: null,
      carTotal: "", //全部页数
      left: "0",
      page: 0,
      // 选择配备
      cities1: [],
      cities2: [],
      alternateValue1: "",
      alternateValue2: "",
      // userList:[],//账户信息
      //勾选
      checkList: false,
      dialogVisible: false, //弹框
      checkedState: false,
      checkList1: [],
      checkedState2: false,
      checkedState3: false,
      searchConfig2: { cid: null, staffName: null }, //另选司机
      submitOff: true, //下单开关
      carShow: true, //显示车类型
    };
  },
  created() {
    this.vehicleTypeId =
      JSON.stringify(this.$route.params.vehicleTypeId) || "1";
    this.brandId = this.$route.params.brandId || null;
  },
  mounted() {
    if (!this.$store.state.accessToken) {
      this.$router.push({ path: "/Login" });
    }
    this.loadData();
  },
  watch: {
    // timeValue() {
    //   if (this.timeValue) {
    //     //获取相关空闲车辆
    //     emptyCar({
    //       vehicleTypeId: this.vehicleTypeId,
    //       brandId: this.brandId,
    //       start: this.timeValue[0],
    //       end: this.timeValue[1],
    //     }).then((res) => {
    //       this.cities1 = res;
    //     });
    //     //获取空闲司机
    //     emptyDriver({
    //       start: this.timeValue[0],
    //       end: this.timeValue[1],
    //     }).then((res) => {
    //       this.cities2 = res;
    //     });
    //   }
    // },
    orderType() {
      if (this.orderType == 3) {
        this.carShow = false;
      } else {
        this.carShow = true;
      }
    },
  },
  methods: {
    withOption(data) {
      //获取用车人电话
      if (data.length == 1) {
        this.options2.forEach((element) => {
          if (data[0] == element.username) {
            this.withPhone = element.mobile;
          }
        });
      }
    },
    loadData() {
      //用户信息
      orderUserInfo({ username: this.$store.state.userName }).then((res) => {
        this.clienteleId = res.clienteleId;
        this.clienteleName = res.clienteleName;
        this.orderPersonTel = res.mobile;
      });
      //用户信息
      userInfo({ username: this.$store.state.userName }).then((res) => {
        this.options2 = res;
      });
      //车辆品牌
      carLista({ vehicleTypeId: this.vehicleTypeId }).then((res) => {
        if (res.code == 0) {
          this.carDataList = res.data;
          this.carTotal = Math.ceil(this.carDataList.length / 4);
          if (!this.brandId) {
            this.brandId = this.carDataList[0].cid;
          }
          this.carBtn2(this.brandId);
        }
      });
    },
    // addPeople() {
    //   let a = 0;
    //   this.num.push(a);
    //   a++;
    // },
    // close(index) {
    //   this.num.splice(index, 1);
    // },
    carBtn() {
      // this.vehicleTypeId = vehicleTypeId;
      // this.vehicleTypeId = this.value3;
      carLista({ vehicleTypeId: this.vehicleTypeId }).then((res) => {
        if (res.code == 0) {
          this.carTotal = "";
          this.page = 0;
          this.left = 0;
          this.carDataList = res.data;
          this.carTotal = Math.ceil(this.carDataList.length / 4);
          this.brandId = this.carDataList[0].cid;
        }
      });
    },
    carBtn2(brandId) {
      this.brandId = brandId;
      // if (this.timeValue.length != 0) {
      emptyCar({
        //获取多余车辆
        vehicleTypeId: this.vehicleTypeId,
        brandId: this.brandId,
        start: this.timeValue[0],
        end: this.timeValue[1],
      }).then((res) => {
        this.cities1 = res;
      });
      // } else {
      //   this.$message.error("用车时间不能为空");
      // }
    },
    leftBtn() {
      if (this.page > 0) {
        this.page--;
        this.left = -(this.page * 1128) + "px";
      }
    },
    rightBtn() {
      if (this.page < this.carTotal - 1) {
        this.page++;
        this.left = -(this.page * 1128) + "px";
      }
    },
    dialogState(index) {
      if (index == 1) {
        this.alternateValue2 = "";
        this.searchConfig2.cid = 0;
        this.searchConfig2.staffName = "自带驾驶员";
        this.checkList = true;
        this.checkedState = true;
      } else {
        this.checkList = false;
        this.checkedState = false;
      }
      setTimeout(() => {
        this.dialogVisible = false;
      }, 50);
    },
    dialogVisibleBtn() {
      if (this.checkedState == false) {
        this.dialogVisible = true;
      } else {
        this.searchConfig2.cid = null;
        this.searchConfig2.staffName = null;
        this.checkedState = false;
      }
    },
    dialogVisibleBtn2() {
      if (this.checkedState2) {
        this.checkedState2 = false;
      } else {
        this.checkedState2 = true;
      }
    },
    dialogVisibleBtn3() {
      if (this.checkedState3) {
        this.checkedState3 = false;
      } else {
        this.checkedState3 = true;
      }
    },
    submitOrder() {
      //下单
      if (!this.submitOff) {
        return;
      }
      if (!this.clienteleId) {
        this.$message.error("客户单位不能为空");
        return;
      }
      if (!this.orderType) {
        this.$message.error("订单类型不能为空");
        return;
      }
      if (!this.reservationStartAddr) {
        this.$message.error("出发地点不能为空");
        return;
      }
      if (!this.reservationEndAddr) {
        this.$message.error("目标地点不能为空");
        return;
      }
      if (this.timeValue[0] == "" || this.timeValue[1] == "") {
        this.$message.error("用车时间不能为空");
        return;
      }
      if (this.value.length == 0) {
        this.$message.error("用车人不能为空");
        return;
      }
      this.value2 = this.value.join(",");
      if (!this.withPhone) {
        this.$message.error("用车人电话不能为空");
        return;
      }
      if (!this.orderPersonTel) {
        this.$message.error("下单人电话不能为空");
        return;
      }
      if (!this.peopleNum) {
        this.$message.error("乘车人数不能为空");
        return;
      }
      this.submitOff = false;
      let formData = new URLSearchParams();
      formData.append("customerId", this.$store.state.userId);
      formData.append("customerName", this.$store.state.userName);
      formData.append("clienteleId", this.clienteleId);
      formData.append("clienteleName", this.clienteleName);
      formData.append("reservationStartAddr", this.reservationStartAddr); //* 预约起点
      formData.append("reservationEndAddr", this.reservationEndAddr); // * 预约终点
      formData.append("reservationStartTime", this.timeValue[0]); //* 预约开始时间
      formData.append("reservationEndTime", this.timeValue[1]); // 预约结束时间

      formData.append("vehicleTypeId", this.vehicleTypeId);
      formData.append("brandId", this.brandId);
      if (this.vehicleId != null) {
        formData.append("vehicleId", this.vehicleId);
      }
      if (this.plateNum != null) {
        formData.append("plateNum", this.plateNum);
      }
      if (this.searchConfig2.cid != null) {
        formData.append("driverId", this.searchConfig2.cid);
      }
      if (this.searchConfig2.staffName != null) {
        formData.append("driverName", this.searchConfig2.staffName);
      }
      formData.append("orderType", this.orderType); // 公务用车（派车派人）2公务用车（派车）3公务用车（派人）4 非公务用车（派车派人）
      formData.append("vehicleUser", this.value2); // 用车人
      formData.append("vehicleUserTel", this.withPhone); // 用车人电话
      formData.append("orderPersonTel", this.orderPersonTel); // * 下单人电话
      formData.append("personNum", this.peopleNum); // * 乘车人数
      // formData.append("vehicleUserPosition", "");//用车人职务
      // formData.append("approvedLeadership", "");//批准领导
      formData.append("vehicleUseReason", this.vehicleUseReason); // 用车事由
      formData.append("vehicleUseRemarks", this.note); //* 用车备注
      formData.append("orderSource", "PC端"); //PC端，移动端，人工下单
      formData.append(
        "followTheMeal",
        this.checkedState2 ? "随从就餐" : "不随从就餐"
      ); //* 是否随从就餐
      formData.append(
        "accomodation",
        this.checkedState3 ? "随从住宿" : "不随从住宿"
      ); // * 是否随从住宿
      subOrder(formData).then((res) => {
        // this.$message.success(res.msg);
        this.$alert(res.msg, "提示", {
          confirmButtonText: "确定",
          callback: (action) => {
            if (res.code == 0) {
              window.scrollTo(0, 0);
              this.reload();
            }
          },
        });
        this.submitOff = true;
      });
    },
    searchSelect1(value) {
      this.vehicleId = this.cities1[value].cid;
      this.plateNum = this.cities1[value].plateNum;
    },
    searchSelect2(value) {
      this.checkList = false;
      this.searchConfig2.cid = this.cities2[value].cid;
      this.searchConfig2.staffName = this.cities2[value].staffName;
    },
    carTime() {
      this.timeValue[0] = this.timeValue1;
      this.timeValue[1] = this.timeValue2;
      if (!this.timeValue[0] || !this.timeValue[1]) {
        return;
      }
      if (this.timeValue[0].substr(11) == this.timeValue[1].substr(11)) {
        if (
          this.timeValue[0] == this.timeValue[1] &&
          this.timeValue[0].substr(11) == this.timeValue[1].substr(11)
        ) {
          this.timeValue = [
            this.timeValue[0].substr(0, 10) +
              " " +
              new Date().getHours() +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
            this.timeValue[1].substr(0, 10) +
              " " +
              (new Date().getHours() + 1) +
              ":" +
              new Date().getMinutes() +
              ":" +
              new Date().getSeconds(),
          ];
        }
        // this.timeValue = [this.timeValue[0].substr(0,10)+' '+new Date().getHours()+':'+new Date().getMinutes()+':'+new Date().getSeconds(),this.timeValue[1].substr(0,10)+' '+(new Date().getHours()+1)+':'+new Date().getMinutes()+':'+new Date().getSeconds()];
      }
    },
  },
};
</script>
<style scoped>
/* 初始化 */
h2 {
  text-align: left;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}
p {
  font-size: 13px;
  color: #333333;
  text-align: left;
}
/* .alternate{
  margin-right: 10px;
} */
.title-selected {
  color: #ff3c28;
}
.title-name {
  width: 90px;
  line-height: 28px;
  height: 28px;
  background-color: #fbfbfb;
  text-align: center;
  font-size: 14px;
  color: #414441;
  border: 1px solid #e6e6e6;
  display: inline-block;
  border-radius: 2px 0 0 2px;
}
.inputCss {
  width: 190px;
  height: 28px;
}
.inputCss >>> .el-input__inner {
  height: 30px;
  line-height: 30px;
  border-radius: 0 2px 2px 0;
}
.online-to {
  background-color: #f5f7fa;
}
.main {
  width: 1200px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 10px 0 74px 25px;
  box-sizing: border-box;
  box-shadow: 1px 5px 9px 1px rgba(0, 0, 0, 0.18);
  margin: 15px auto 22px auto;
}
.whit-people {
  width: 988px;
}
.carlmd > div {
  margin-right: 5px;
}
.car-time{
  display: flex;
  align-items: center;
}
.car-time >>> .el-input{
  width: 236px;
}
.car-time >>> .el-input__inner {
  /* width: 477px; */
  width: 236px;
  height: 30px;
  border-radius: 0;
  /* border-radius: 0 4px 4px 0; */
}
.car-time  >>> .el-input__icon {
  line-height: 28px;
}
.el-date-editor >>> .el-range__icon {
  line-height: 20px;
}
.car-time >>> .el-range__close-icon {
  line-height: 20px;
}
.car-time >>> .el-date-editor .el-range-separator {
  line-height: 20px;
}
/* .car-time >>> .el-date-editor .el-range-separator {
  line-height: 20px;
} */
/* .car-time >>> .el-date-editor .el-range__close-icon {
  line-height: 20px;
} */
.with-car >>> .el-input__inner {
  /* height: auto !important; */
  border-radius: 0 4px 4px 0;
}
.with-car >>> .el-input {
  width: 190px;
  /* height: 28px; */
}
.with-car .title-name {
  height: 28px;
  line-height: 28px;
}
.with-car >>> .el-input__icon {
  line-height: 28px;
}
.inputCss >>> .el-input__icon {
  line-height: 20px;
}
.target-site >>> .el-input__inner {
  height: 30px;
  line-height: 30px;
  border-radius: 0 4px 4px 0;
}
.target-site >>> .el-input__icon {
  line-height: 20px;
}
.target-site >>> .el-select {
  width: 190px;
}
/* .with-car >>>.el-input__icon{
  line-height: 20px;
} */
/* 头部 */
.basic > div {
  margin-right: 5px;
}
.basic > div:last-child {
  margin-right: 0;
}
.user-from > div {
  margin-bottom: 10px;
}
.user-from > div:last-child {
  margin-bottom: 0;
}
.consulting-phone {
  margin-bottom: 11px;
}
.consulting-phone img {
  margin-right: 7px;
}
.consulting-phone div {
  font-size: 16px;
  font-weight: 400;
  color: #3f3f3f;
}
.consulting-phone span {
  font-size: 20px;
  color: #ff3c28;
}
.prompt {
  margin-bottom: 47px;
}
.prompt span {
  display: block;
  width: 1104px;
  padding: 12px 0 12px 18px;
  font-size: 16px;
  text-align: left;
  box-sizing: border-box;
  border: 1px solid;
  margin-bottom: 6px;
}
.prompt span:first-child {
  border-color: #3991fb;
  color: #3991fb;
}
.prompt span:last-child {
  border-color: #ff7628;
  color: #ff7628;
}
/* 填写信息 */
.message {
  margin-bottom: 10px;
}
.message > p {
  margin-bottom: 10px;
  display: block;
}
.select-time div {
  width: 77px;
  height: 22px;
  background: #3991fb;
  box-shadow: 1px 5px 9px 1px rgba(57, 145, 251, 0.34);
  border-radius: 3px;
  margin-right: 11px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
  line-height: 22px;
}
.with-car button {
  width: 107px;
  height: 30px;
  background: #ffb800;
  border-radius: 0px 3px 3px 0px;
  color: #ffffff;
  line-height: 28px;
  border: none;
  cursor: pointer;
}
/* 选择类型 */
.car-item {
  width: 280px;
  height: 251px;
  border: 1px solid #ebebeb;
  padding: 12px 0 16px 0;
}
.car-item span {
  font-size: 16px;
  font-weight: 400;
  color: #6b6b6b;
}
.car-item img {
  width: 253px;
  height: 145px;
}
.car-item button {
  width: 253px;
  height: 42px;
  border: 1px solid #9f9f9f;
  border-radius: 3px;
  font-size: 16px;
  color: #ffffff;
  line-height: 29px;
  cursor: pointer;
  background-color: #ffffff;
  color: #6b6b6b;
}
.car-item .selected-btn {
  background: #3991fb;
  border: 1px solid #3991fb;
  color: #ffffff;
}
/* 选择品牌 */
.select-car {
  margin-bottom: 10px;
}
.car-item .selected-btn2 {
  background: #3991fb;
  border: 1px solid #3991fb;
  color: #ffffff;
}
.select-car2 {
  width: 1128px;
  position: relative;
  height: 281px;
  overflow: hidden;
}
.car-btn {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  cursor: pointer;
  line-height: 30px;
}
.car-left-btn {
  left: 20px;
}
.car-right-btn {
  right: 20px;
}
.brand-car {
  position: absolute;
  height: 281px;
  /* overflow-y: auto; */
}
/* 选择配备 */
.target-site {
  margin-bottom: 10px;
  margin-right: 5px;
}
.selectCheck {
  height: 80px;
}
.selectCheck-item1 {
  height: 20px;
  margin-bottom: 5px;
}
.selectCheck-item {
  height: 40px;
}
.selectCheck-item .el-checkbox {
  margin-right: 10px;
  margin-bottom: 5px;
}
.submit-order {
  width: 126px;
  height: 42px;
  background: #3991fb;
  border: 1px solid #3991fb;
  border-radius: 3px;
  font-size: 16px;
  color: #ffffff;
  line-height: 29px;
  margin: 20px auto 0 auto;
  cursor: pointer;
}
</style>
